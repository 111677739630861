<template>
  <div>
    <div class="vx-row">
      <vx-card title="CSV Mapping">
        <div class="centerx">
          <vs-row>
            <vs-col vs-type="flex" vs-align="center" vs-w="12">
              <h6 class="mb-4">Ordne die Felder den passenden Feldern zu:</h6>
            </vs-col>
          </vs-row>
          <vs-row vs-w="12">
            <vs-table :data="dataImportModels" vs-col="12">
              <template slot="header">
                <h3>
                  Daten
                </h3>
              </template>
              <template slot="thead">
                <vs-th v-for="item in dataImportModels">
                  {{item}}
                </vs-th>
              </template>
              <template slot-scope="dataImportModels">
                <vs-tr v-for="csvModelRow in dataImportCsv">
                  <vs-td v-for="modelDataIn in csvModelRow">
                    {{modelDataIn}}
                  </vs-td>
                </vs-tr>
                <vs-tr v-for="csvModelTr in dataImportModels">
                  <vs-td v-for="csvmodelTd in csvModelTr">
                    <mg-select v-model="datatype" track-by="id" label="name" :options="dropDownDatas"></mg-select>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-row>
          {{dataImportCsv}}
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
    import ApiService from "../../../api/index";
    import MgSelect from "../../../components/mg-select/MgSelect";

    export default {
        components: {
            MgSelect,
        },
        created() {
            this.fetchImportData();
        },
        data() {
            return {
                dataImportCsv: [],
                dataImportModels: [],
                newModelData: ''
            }
        },
        methods: {
            fetchImportData() {
                ApiService.get('data-import/49?step=csv-phrasen').then(response => {
                    this.dataImportCsv = response.data.result.csvData;
                    this.dataImportModels = response.data.result.modelFields.splice(1);
                }).catch(response => {
                })
            }
        }
    }
</script>
